<template>
	<div>
		<div class="tw-mt-3 tw-mb-6">
			<h1 class="section-divider-underline">{{ $t("employee.documents") }}</h1>
			<div class="overflow-auto tw-mt-3 tw-max-h-40" style="min-height: 100px;">
				<div class="table-ui-detail">
					<table>
						<thead>
							<tr>
								<th width="20%">
									{{ $t("employee.documentType") }}
								</th>
								<th width="20%">
									{{ $t("employee.cardNumber") }}
								</th>
								<th width="20%">
									{{ $t("employee.issuedDate") }}
								</th>
								<th width="20%">
									{{ $t("employee.expiryDate") }}
								</th>
								<th width="20%">
									{{
										$t("employee.issuedAt")
									}}
								</th>
								<th style="text-align:center; width: 1px;" width="5%">
									<Tooltip placement="top" :transfer="true" :content="$t('add')">
										<a href="#" class="text-primary" @click.prevent="onAddItem()">
											<i class="fas fa-plus"></i> </a></Tooltip>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(detail, index) in items" :key="index">
								<td>
									<Select v-model="detail.document_type_id" :class="{
										'ivu-form-item-error': errors.has('details.' +
											index + '.document_type_id')
									}">
										<Option v-for="(type, key) in types1" :value="type.document_type_id" :key="type.document_type_id+key"
											v-show="model.emp_type_id === 1">
											{{ type.document_type }}
										</Option>
										<Option v-for="(type, i) in types2" :value="type.document_type_id" :key="type.document_type_id+i"
											v-show="model.emp_type_id === 2">
											{{ type.document_type }}
										</Option>
									</Select>
									<div class="validate text-danger" v-if="errors.has('details.' + index + '.document_type_id')">
										{{ errors.first('details.' + index + '.document_type_id') }}
									</div>
								</td>
								<td>
									<Input v-model="detail.card_number" style="width: 100%"
										:class="{ 'ivu-form-item-error': errors.has('details.' + index + '.card_number') }"
										class="tw-text-right">
									</Input>
									<div class="validate text-danger" v-if="errors.has('details.' + index + '.card_number')">
										{{ errors.first('details.' + index + '.card_number') }}
									</div>
								</td>
								<td>
									<DatePicker type="date" :value="detail.issued_date" style="width:100%" placeholder="DD-MM-YYYY"
										format="dd-MM-yyyy" @on-change="onChangeDateIssue($event, detail)"></DatePicker>
									<div class="validate text-danger" v-if="errors.has('details.' + index + '.issued_date')">
										{{ errors.first('details.' + index + ".issued_date") }}
									</div>
								</td>
								<td>
									<DatePicker type="date" :value="detail.expiry_date" style="width:100%" placeholder="DD-MM-YYYY"
										format="dd-MM-yyyy" @on-change="onChangeDateExpire($event, detail)"></DatePicker>
									<div class="validate text-danger" v-if="errors.has('details.' + index + '.expiry_date')">
										{{ errors.first('details.' + index + ".expiry_date") }}
									</div>
								</td>
								<td>
									<textarea v-model.trim="detail.issued_at" rows="1" class="form-control"></textarea>
								</td>
								<td class="text-center">
									<Tooltip placement="top" :transfer="true" :content="$t('remove')">
										<a href="#" class="text-danger" @click.prevent="
											removeRow(detail)
											">
											<Icon type="ios-trash" size="20" />
										</a>
									</Tooltip>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="validate text-danger" v-if="errors.has('details')">
				{{ errors.first("details") }}
			</div>
		</div>
	</div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions } from "vuex";

export default {
	name: "document",
	props: ["value", "validation"],
	data() {
		return {
			model: {},
			types1: [],
			types2: [],
			errors: new Errors()
		};
	},
	computed: {
		items() {
			return this.model.details;
		}
	},
	created() {
		this.model = this.value;
		this.fetchDocument()
	},
	methods: {
		...mapActions("humanResource/employee", ['documentType']),
		fetchDocument() {
			this.documentType().then(respose => (
				this.types1 = respose.data.filter(el => el.employee_type_id == 1),
				this.types2 = respose.data.filter(el => el.employee_type_id == 2)
			));
		},
		onChangeDateIssue(date, item) {
			item.issued_date = date
		},
		onChangeDateExpire(date, item) {
			item.expiry_date = date
		},
		onAddItem() {
			this.model.details.push({
				employee_type_id: this.model.emp_type_id,
				document_type_id: null,
				card_number: null,
				issued_date: null,
				issued_at: null,
				expiry_date: null
			});
		},
		removeRow(item) {
			this.model.details.splice(this.model.details.indexOf(item), 1);
		},
	},
	watch: {
		validation: {
			handler() {
				this.errors = new Errors(this.validation.errors);
			},
			deep: true
		}
	}
};
</script>

<style lang="css" scoped>
.section-divider-underline {
	padding-bottom: 5px;
	margin: 0 0 8px 0;
	/* border-bottom: 1px solid #eee; */
	font-size: 16px;
	color: #929090;
	font-weight: 400;
	text-transform: uppercase;
}
</style>
